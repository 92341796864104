<template>
  <div :class="{ 'is-active': isActive }" class="slideout-sidebar">
    <ul>
      <li>
        <a href="/"><img src="assets/img/logo_2025.svg" class="img-fluid slideout-logo" alt="Logo"/></a>
      </li>
      <li v-for="route in routes" v-on:click="close">
        <a :href="route.url" :title="route.title">
          {{ route.name }}
        </a>
      </li>
    </ul>

    <div class="social-media-bar is--mobile">
      <a href="https://www.instagram.com/sg_posters/" target="_blank" title="Follow me on instagram">
        <i class="bi bi-instagram"></i>
      </a>
      <a href="https://www.facebook.com/sgposters/" target="_blank" title="Follow me on facebook">
        <i class="bi bi-facebook"></i>
      </a>
      <a href="https://www.twitter.com/sg_posters/" target="_blank" title="Follow me on twitter">
        <i class="bi bi-twitter"></i>
      </a>
      <a href="https://www.linkedin.com/in/sgposters-eileen-steinbach/" target="_blank" title="Follow me on linkedin">
        <i class="bi bi-linkedin"></i>
      </a>
    </div>
  </div>
</template>

<style>
.slideout-sidebar {
  box-shadow: 2px 0 2px 0 rgba(33, 37, 41, 0.3);
  background-color: #130f0d;
  height: 100vh;
  position: fixed;
  left: -310px;
  top: 0;
  transition: all 300ms ease-in-out;
  width: 300px;
  z-index: 999999;
}

.slideout-logo {
  padding-top: 20px;
  width: 130px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.slideout-sidebar ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}

.slideout-sidebar ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  list-style: none;
  text-transform: uppercase;
}

.slideout-sidebar ul a {
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: normal;
  padding: 20px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.slideout-sidebar ul a:hover {
  color: #d0cece;
}

.slideout-sidebar.is-active {
  left: 0;
}
</style>

<script>
export default {
  name: 'MobileMenu',
  components: {},
  mounted() {
    this.$root.$on('toggleSidebar', () => {
      this.toggleActive();
    });

    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  data() {
    return {
      routes: [
        {
          url: '/key-art',
          name: 'Key Art',
          title: 'Key Art',
        },
        {
          url: '/promotional-art',
          name: 'Promotional Art',
          title: 'Promotional Art',
        },
        {
          url: '/tribute-art',
          name: 'Tribute Art',
          title: 'Tribute Art',
        },
        {
          url: '/about',
          name: 'About',
          title: 'About',
        },
        {
          url: '/contact',
          name: 'Contact',
          title: 'Contact',
        },
        {
          url: '/imprint-and-data-privacy',
          name: 'Imprint & Data Privacy',
          title: 'Imprint & Data Privacy',
        },
      ],
      isActive: false,
    };
  },
  methods: {
    toggleActive: function () {
      this.isActive = !this.isActive;
    },
    close: function () {
      this.isActive = false;
      this.$root.$emit('mobileMenuClosed');
    },
    onResize: function () {
      let width = window.innerWidth;
      if (width > 767 && this.isActive) {
        this.isActive = false;
        this.$root.$emit('mobileMenuClosed');
      }
    },
  },
};
</script>
